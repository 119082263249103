/* eslint-disable react/jsx-no-useless-fragment */
import i18next from "i18next";
import language_static from "language_static.json";
import React from "react";
import { initReactI18next } from "react-i18next";

import Loading from "../Loading";

export const LanguageProvider: React.FC = ({ children }) => {
  const [init, setInit] = React.useState(false);

  React.useEffect(() => {
    i18next
      .use(initReactI18next)
      .init({
        resources: language_static,
        lng: "en",
        fallbackLng: "en",
        interpolation: {
          escapeValue: false,
        },
      })
      .then(() => setInit(true));
  }, []);

  if (!init) {
    return <Loading open />;
  }

  return <>{children}</>;
};
