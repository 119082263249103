import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { SnackbarProvider } from "notistack";
import React from "react";

import { ApolloClientProvider } from "./ApolloClientProvider";
import { ThemeProvider } from "./ThemeProvider";

import { LanguageProvider } from "components/providers/LanguageProvider";

import { AuthProvider } from "contexts/AuthContext";
import { IntlProvider } from "contexts/IntlContext";
import { SettingsProvider } from "contexts/SettingsContext";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY ?? "");

export const Providers: React.FC = ({ children }) => (
  <ApolloClientProvider>
    <IntlProvider>
      <SnackbarProvider dense maxSnack={3}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <AuthProvider>
                <Elements stripe={stripePromise}>
                  <ThemeProvider>
                    <LanguageProvider>{children}</LanguageProvider>
                  </ThemeProvider>
                </Elements>
              </AuthProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </SnackbarProvider>
    </IntlProvider>
  </ApolloClientProvider>
);
