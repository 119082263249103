import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  fromPromise,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { refreshToken as fetchRefreshToken } from "queries/auth";
import React from "react";

const authLink = setContext(() => {
  const token = localStorage.getItem("access-token");
  return {
    headers: {
      Authorization: token,
    },
  };
});

const errorLink = onError(
  // eslint-disable-next-line consistent-return
  ({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors) {
      const tokenHasExpired = graphQLErrors.some(
        (err) => err.message === "Token Expired"
      );
      const oldRefreshToken = localStorage.getItem("refresh-token");

      if (tokenHasExpired && oldRefreshToken) {
        return fromPromise(
          fetchRefreshToken({
            token: oldRefreshToken,
          }).catch(() => {
            localStorage.removeItem("access-token");
            localStorage.removeItem("refresh-token");
          })
        )
          .filter((result) => Boolean(result))
          .flatMap((result) => {
            if (!result || !result.data) {
              throw new Error("Refresh token failed b");
            }

            const { accessToken, refreshToken, idToken } =
              result.data.refreshToken;

            localStorage.setItem("access-token", accessToken);
            localStorage.setItem("refresh-token", refreshToken);
            localStorage.setItem("id-token", idToken);

            const oldHeaders = operation.getContext().headers as Record<
              string,
              string
            >;

            operation.setContext({
              headers: {
                ...oldHeaders,
                Authorization: accessToken,
              },
            });

            return forward(operation);
          });
      }
    }

    if (networkError && process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError.message}`);
    }
  }
);

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_BACKEND_URL,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) as unknown as ApolloClient<any>;

export const ApolloClientProvider: React.FC = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);
